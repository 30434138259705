<template>
	<div class="p-grid">
        <Toast/>
        <div class="p-col-12">
            <div class="card">

                <h4>Teljesítés</h4>
                <h5>Költségek, vizitek elszámolása</h5>

                <div>
                    <span style="width: 5rem; display: inline-block;">Év, hó</span>
                    <div class="p-d-inline-block">
                        <Button icon="pi pi-chevron-left" class="p-button-text" v-tooltip.bottom="'Előző hó'"
                                @click="prevMonth()"/>
                        <Calendar id="yearmonth" name="x-usercost-datum" v-model.lazy="yearmonth" view="month" dateFormat="yy.mm" 
                                    style="width: 7rem" autocomplete="off"
                                    :yearNavigator="true" yearRange="2000:2030"
                                    @change="loadData()" @date-select="loadData()" />
                        <Button icon="pi pi-chevron-right" class="p-button-text" v-tooltip.bottom="'Következő hó'"
                                @click="nextMonth()"/>
                        <Badge :value="statusLabels[status]" :class="'status-'+status" style="margin-left: 2.5rem"/>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12">
            <div class="card">
                <DataTable  ref="dt" :value="costs" dataKey="id" :loading="loading"
                            sortField="id" :sortOrder="1" 
                            v-model:expandedRows="expandedRows" @row-click="expandRow" :rowClass="showExpander"
                            class="p-datatable-customers p-datatable-hoverable-rows" style="max-width: 1200px">

                    <Column :expander="true" headerStyle="width: 3rem" />
                    <Column field="label" header="Tétel" headerStyle="font-weight: 700">
                    </Column>
                    <Column field="count" header="Mennyiség" headerStyle="font-weight: 700; width: 8rem">
                    </Column>
                    <Column field="vizitlap" header="Vizitlapok" headerStyle="font-style: italic" style="font-style: italic">
                    </Column>
                    <Column field="ppu" header="Egységár" headerStyle="font-weight: 700">
                        <template #body="slotProps">
                            <span v-if="slotProps.data.ppu > 0">{{ slotProps.data.ppu.toLocaleString() }} Ft</span>
                        </template>
                    </Column>
                    <Column field="cost" header="Összeg" headerStyle="font-weight: 700">
                        <template #body="slotProps">
                            <span>{{ slotProps.data.cost.toLocaleString() }} Ft</span>
                        </template>
                    </Column>

                    <template #expansion="slotProps">
                        <Card>
                        <template #content>
                            <DataTable  ref="dt_costs" :value="slotProps.data.fees" dataKey="id" 
                                        class="p-datatable-customers p-datatable-hoverable-rows l-datatable-nar-rows">

                                <Column field="visitdate" headerStyle="height: 0px"/>
                                <Column headerStyle="height: 0px">
                                    <template #body="slotProps">
                                        <router-link :to="'/beteg/' + slotProps.data.patientid" style="white-space: pre-wrap">
                                            {{ slotProps.data.fname }}
                                        </router-link>
                                    </template>
                                </Column>
                                <Column headerStyle="height: 0px" v-if="slotProps.data.label!=='Konzultációs díj' && slotProps.data.label!=='Tanácsadás'">
                                    <template #body="slotProps">
                                        <span>{{ (slotProps.data.vizitlap) ? "Vizitlap OK" : "Nincs vizitlap" }}</span>
                                    </template>
                                </Column>
                                <Column field="visitname" headerStyle="height: 0px"/>
                                <Column field="visitcost" headerStyle="height: 0px">
                                    <template #body="slotProps">
                                        <span>{{ slotProps.data.visitcost.toLocaleString() }} Ft</span>
                                    </template>
                                </Column>
                            </DataTable>
                        </template>
                        </Card>
                    </template>

                    <template #footer>
                        <div class="p-grid" style="font-weight: 700">
                            <div class="p-col-8" style="text-align: right">
                                Összesen:
                            </div>
                            <div class="p-col-4" style="padding-left: 1rem">
                                {{ costsSum.toLocaleString() }} Ft
                            </div>
                        </div>
                    </template>

                    <template #empty>
                        <div style="padding: 3rem; width: 40rem; margin: 0 auto" v-if="!loading">
                            Nincs egyetlen teljesítés sem a megadott hónapra!
                        </div>
                    </template>

                </DataTable>
            </div>
        </div>
        <div class="p-col-12">
            <div class="card">

                <h5>Csatolt fájlok</h5>

                    <p>Kérlek ide töltsd fel a havi elszámoláshoz szükséges dokumentumokat.</p>
                    <div class="p-d-inline-block" style="width:100%">
                        <Dropdown v-model="fileType" :options="selectableFileTypes" placeholder="Feltöltendő fájl típusa" 
                                  style="min-width: 200px" class="p-mr-4" :class="{'p-invalid': isFileSelected && fileType === null}"
                        />
                        <FileUpload name="files[]" mode="basic" class="p-d-inline p-mr-4" :fileLimit="20971520" :key="fileUploadKey" accept=".xls,.xlsx,.png,.jpg,.pdf" :customUpload="true" @uploader="myFileUploader"
                                    chooseLabel="Választ" uploadLabel="Feltölt" @select="onFileSelect"
                                    v-tooltip.bottom="fileUploadTooltip" 
                        />
                        <Button icon="pi pi-times" @click="cancelUpload()" :disabled="!isFileSelected" v-tooltip.bottom="'Mégse'"
                        />
                    </div>

                    <DataTable :value="uploadedFiles" editMode="row" dataKey="id" responsiveLayout="scroll" style="max-width: 1000px">
                        <Column field="filename" header="Fájlnév" style="width:40%">
                            <template #body="slotProps">
                                <Button :label="slotProps.data.filename" icon="pi pi-download" @click="downloadFile(slotProps.data.id, slotProps.data.filename)"/>
                            </template>
                        </Column>
                        <Column field="type" header="Típus" headerStyle="width: 10rem">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                        </Column>
                        <Column field="created" header="Feltöltve" headerStyle="width: 10rem">
                            <template #editor="{ data, field }">
                                <InputText v-model="data[field]" />
                            </template>
                        </Column>
                        <!--Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column-->
                        <Column style="width:10%; min-width:8rem">
                            <template #body="slotProps">
                                <!--Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editTool(slotProps.data.id)" /-->
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-outlined p-button-warning" @click="confirmDelete(slotProps.data.id)" />
                            </template>
                        </Column>
                    </DataTable>
                    
            </div>
        </div>
    </div>
</template>
<script>
import UsercostService from '@/service/backend/usercost.service'
export default {
    data() {
        
        return {
            loading: true,
            expandedRows: [],

            yearmonth: null,
            yearmonthparam: null,

            costs: [],
            status: 'N',

            statusLabels: {'N': 'Nem jóváhagyott', 'I': 'Jóváhagyott, számlázható'},

            selectableFileTypes: ['Km tábla', 'Teljesítési igazolás'],
            fileType: null,
            isFileSelected: false,
            fileUploadKey: 0,
            uploadedFiles: [],
            editingRows: [],

        }
    },
    usercostService: null,
	created() {
        this.yearmonth = this.$dayjs().toDate()
		this.usercostService = new UsercostService()
    },
	mounted() {
        this.loadData()
    },
    methods: {
        loadData: async function() {
            this.expandedRows = []
            this.loading = true
            try{
                this.yearmonthparam = this.$dayjs(this.yearmonth).format('YYYYMM')
                const result = await this.usercostService.getUsercost(this.yearmonthparam) 
                this.status = result.status
                this.costs = result.data
                this.uploadedFiles = result.uploadedFiles ?? []
                
                if(this.status === "I")
                    this.fileType = "Teljesítési igazolás"
                else
                    this.fileType = "Km tábla"

            }catch(e){
                console.log(e)
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: 'Valami hiba történt', life: 3000})
            }
            this.loading = false
        },
        prevMonth() {
            this.yearmonth = this.$dayjs(this.yearmonth).subtract(1, 'month').toDate()
            this.loadData()
        },
        nextMonth() {
            this.yearmonth = this.$dayjs(this.yearmonth).add(1, 'month').toDate()
            this.loadData()
        },
        expandRow(e) {
            if(this.expandedRows.length === 0 || this.expandedRows[0].id !== e.data.id ){
                this.expandedRows = this.costs.filter(c => c.id == e.data.id);
            }else{
                this.expandedRows = []
            }
        },
        showExpander(data) {
            return data.count > 0 ? 'yes-expander' : 'no-expander'
        },
        async myFileUploader(event) {

            if(this.fileType === null){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Válaszd ki a fájl típusát!", life: 3000})
                return
            }

            try{
                const type = this.fileType.valueOf();
                const uploadedFile = await this.usercostService.uploadFile(this.yearmonthparam, type, event.files[0])
                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Feltöltve!', life: 3000})
                const today = new Date()
                this.uploadedFiles.push({id: uploadedFile.id, filename: event.files[0].name, type, created: today.toLocaleDateString()})
                this.cancelUpload()
                this.fileType = null
                
            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Valami hiba történt!", life: 3000})
                console.log(e)
            }


        },
        async onFilesRowEditSave() {

        },
        cancelUpload() {
            this.fileUploadKey++
            this.isFileSelected = false
        },
        onFileSelect() {
            this.isFileSelected = true
        },
        async downloadFile(fileId, fileName) {
            try{
                const downloadedFile = await this.usercostService.downloadFile(fileId)

                this.$toast.add({severity:'success', summary: 'Siker!', detail: 'Letöltve!', life: 3000})

                const blo = new Blob([downloadedFile])
                const url = window.URL.createObjectURL(blo)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)
                link.click()

            }catch(e){
                this.$toast.add({severity:'error', summary: 'Hiba!', detail: "Nincs ilyen fájl!", life: 3000})
                console.log(e)
            }
        },

    },
    computed: {
        costsSum() {
            const sum = this.costs.reduce((total, e) => total + e.cost, 0)
            return sum.toLocaleString()
        },
        fileUploadTooltip() {
            return (this.isFileSelected) ? 'Fájl feltöltése' : ''
        }
    }
}
</script>
<style>

.l-datatable-nar-rows td {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

</style>
<style scoped>
#maincard {
    padding-bottom: 40px;
}
.p-datatable-tbody {
    cursor: auto;
}
.p-field {
    display: inline-flex;
    margin-right: 3rem;
}

.p-badge{border-radius:2px;padding:.25em .5rem;text-transform:uppercase;font-weight:700;font-size:12px;letter-spacing:.3px; background-color:#c8e6c9;color:#256029}
.p-badge.status-N{background-color:#ffcdd2;color:#c63737}

:deep(.p-card-content) {
    padding-top: 0;
}
:deep(.p-datatable-header, .p-datatable-thead) {
    height: 0px;
}

:deep(.yes-expander) {
    cursor: pointer;
}
.p-datatable-row-expansion {
    cursor: auto;
}

:deep(.no-expander .p-row-toggler) {
    opacity: 0;
    pointer-events: none;
    cursor: unset;
}

</style>