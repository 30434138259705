import ApiService from './api.service'

export default class UsercostService {

	async getUsercost(yearmonth) {
        const response = await ApiService.get('api/usercosts/'+yearmonth) // Nem elírás, a usercosts.show()-t szólítjuk meg backend-en
        return response.data
    }

	async uploadFile(yearmonth, type, file) {
        let formData = new FormData()
        formData.append('yearmonth', yearmonth)
        formData.append('type', type)
        formData.append('file', file)
        const response = await ApiService.post('api/usercosts/upload', formData)
        return response.data
    }

	async downloadFile(fileId) {
        const requestData = {
            method: 'get',
            url: "api/usercosts/download",
            params: {
                id: fileId
            },
            responseType: 'blob',
        }
        const response = await ApiService.customRequest(requestData)
        return response.data
    }

/*
	async newUsercost(usercost) {
        const response = await ApiService.post('api/usercosts', usercost)
        return response.data.id
    }

	async updateUsercost(usercost) {
        const response = await ApiService.put('api/usercosts/'+usercost.id, usercost)
        return response.data.id
    }

	async deleteUsercost(id) {
        const response = await ApiService.delete('api/usercosts/'+id)
        return response
    }
*/
}
